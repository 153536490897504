import { useLazyQuery } from '@apollo/client';
import { ORDER_INSIGHTS } from './orders.graphql';
import { type OrderInsights } from './orders.types';

interface QueryResponse {
  getOrderInsights: OrderInsights;
}

export function useOrderInsightsQuery() {
  const [query, { data, loading, error }] = useLazyQuery<QueryResponse>(ORDER_INSIGHTS);

  function getOrderInsights() {
    void query({ pollInterval: 30000 });
  }

  return {
    getOrderInsights,
    insights: data?.getOrderInsights,
    loading,
    error,
  };
}
